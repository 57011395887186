.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  max-height: 50px;
  border-radius: 10px;
  object-fit: cover;
}
